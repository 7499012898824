$bp-27inch: 1920px;
$bp-15inch: 1680px;
$bp-13inch: 1440px;
$bp-1280:   1280px;
$bp-tablet: 1024px;
$bp-mobile: 768px;

@mixin widescreen {
  @media only screen and (min-width: $bp-27inch) {
    @content;
  }
}

@mixin default {
  @media only screen and (max-width: $bp-15inch) {
    @content;
  }
}

@mixin notebook {
  @media only screen and (max-width: $bp-13inch) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $bp-tablet) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $bp-mobile) {
    @content;
  }
}
