header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 17px 0 18px;
  display: flow-root;
  z-index: $zIndex-10;

  @include mobile {
    padding: 20px 0 15px;
  }

  &.nav-up {
    transform: translateY(-130px);
    transition: transform 0.3s linear;
  }

  &.nav-down {
    transform: translateY(0);
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
  }

  @include link-color;

  .logo {
    float: left;
    width: 200px;
    height: auto;
    margin-right: 20px;

    svg {
      vertical-align: middle;
      width: 100%;
      height: auto;
      user-select: none;

      #logo-color {
        fill: $plum;
        transition: all 0.2s ease;

        .color-dark & {
          fill: $rose;
        }

        .color-dark.header-out & {
          fill: $plum;
        }
      }
    }
    @include mobile {
      width: 240px;
    }

    a {
      display: block;
    }
  }

  .burger {
    display: none;
    @include mobile {
      display: inherit;
    }
    position: fixed;
    z-index: $zIndex-10;
    width: 40px;
    height: 40px;
    top: 20px;
    right: 20px;
    background: $plum;
    border-radius: 3px;

    span {
      @include burger(20px, 2px, 6px, $rose); // gray
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -9px);
    }

    &.clicked {
      span {
        @include burger-to-cross;

        &:after,
        &:before {
          background-color: $rose;
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .overlay {
    display: none;
    position: fixed;
    z-index: $zIndex-8;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $plum-050;
    z-index: 8;

    .mobile-nav & {
      display: inherit;
    }
  }

  nav {
    @include mobile {
      padding-top: 10px;

      ul {
        li {
          width: 100% !important;
          margin-left: 0 !important;

          ul {
            position: relative !important;
            visibility: visible !important;
            display: inline-block !important;
            width: 100% !important;

            li {
              width: 100% !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
    margin-top: -5px;
    @include mobile {
      margin-top: 0;
    }
    @include mobile {
      position: fixed;
      z-index: $zIndex-9;
      top: 0;
      right: -80vw;
      height: 100vh;
      width: 80vw;
      background: $rose;
      transform: translate3d(0,0,0);
      transition: transform 0.3s ease;

      .mobile-nav & {
        transform: translate3d(-100%,0,0);
      }
    }

    a {
      .color-dark & {
        color: $rose;
        @include mobile {
          color: $plum;
        }
      }

      .color-dark.header-out & {
        color: $plum;
      }
    }

    ul {
      list-style: none;
      user-select: none;
      @include mobile {
        padding: 30px;
      }

      &:first-child > li:first-child {
        &:after {
          content: url("data:image/svg+xml;utf8,<svg width='9px' height='15px' viewBox='0 0 9 15' version='1.1' xmlns='http://www.w3.org/2000/svg'><g id='home' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><path d='M1.53033009,5.06586399 C1.23743687,5.35875721 0.762563133,5.35875721 0.469669914,5.06586399 C0.176776695,4.77297077 0.176776695,4.29809704 0.469669914,4.00520382 L4.00520382,0.469669914 C4.29809704,0.176776695 4.77297077,0.176776695 5.06586399,0.469669914 L8.6013979,4.00520382 C8.89429112,4.29809704 8.89429112,4.77297077 8.6013979,5.06586399 C8.30850468,5.35875721 7.83363094,5.35875721 7.54073773,5.06586399 L4.53553391,2.06066017 L1.53033009,5.06586399 Z M7.54073773,10.0052038 C7.83363094,9.7123106 8.30850468,9.7123106 8.6013979,10.0052038 C8.89429112,10.298097 8.89429112,10.7729708 8.6013979,11.065864 L5.06586399,14.6013979 C4.77297077,14.8942911 4.29809704,14.8942911 4.00520382,14.6013979 L0.469669914,11.065864 C0.176776695,10.7729708 0.176776695,10.298097 0.469669914,10.0052038 C0.762563133,9.7123106 1.23743687,9.7123106 1.53033009,10.0052038 L4.53553391,13.0104076 L7.54073773,10.0052038 Z' id='arrows-light' fill='#{$plum}' fill-rule='nonzero'></path></g></svg>");
          display: inline-block;
          user-select: none;
          padding-left: 5px;
          margin-left: -3px;
          transform: scale(.9);
          cursor: pointer;
          @include mobile {
            content: none;
            padding: 0;
            margin: 0;
          }

          .color-dark & {
            content: url("data:image/svg+xml;utf8,<svg width='9px' height='15px' viewBox='0 0 9 15' version='1.1' xmlns='http://www.w3.org/2000/svg'><g id='home' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><path d='M1.53033009,5.06586399 C1.23743687,5.35875721 0.762563133,5.35875721 0.469669914,5.06586399 C0.176776695,4.77297077 0.176776695,4.29809704 0.469669914,4.00520382 L4.00520382,0.469669914 C4.29809704,0.176776695 4.77297077,0.176776695 5.06586399,0.469669914 L8.6013979,4.00520382 C8.89429112,4.29809704 8.89429112,4.77297077 8.6013979,5.06586399 C8.30850468,5.35875721 7.83363094,5.35875721 7.54073773,5.06586399 L4.53553391,2.06066017 L1.53033009,5.06586399 Z M7.54073773,10.0052038 C7.83363094,9.7123106 8.30850468,9.7123106 8.6013979,10.0052038 C8.89429112,10.298097 8.89429112,10.7729708 8.6013979,11.065864 L5.06586399,14.6013979 C4.77297077,14.8942911 4.29809704,14.8942911 4.00520382,14.6013979 L0.469669914,11.065864 C0.176776695,10.7729708 0.176776695,10.298097 0.469669914,10.0052038 C0.762563133,9.7123106 1.23743687,9.7123106 1.53033009,10.0052038 L4.53553391,13.0104076 L7.54073773,10.0052038 Z' id='arrows-light' fill='#{$rose}' fill-rule='nonzero'></path></g></svg>");
            @include mobile {
              content: none;
            }
          }

          .color-dark.header-out & {
            content: url("data:image/svg+xml;utf8,<svg width='9px' height='15px' viewBox='0 0 9 15' version='1.1' xmlns='http://www.w3.org/2000/svg'><g id='home' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><path d='M1.53033009,5.06586399 C1.23743687,5.35875721 0.762563133,5.35875721 0.469669914,5.06586399 C0.176776695,4.77297077 0.176776695,4.29809704 0.469669914,4.00520382 L4.00520382,0.469669914 C4.29809704,0.176776695 4.77297077,0.176776695 5.06586399,0.469669914 L8.6013979,4.00520382 C8.89429112,4.29809704 8.89429112,4.77297077 8.6013979,5.06586399 C8.30850468,5.35875721 7.83363094,5.35875721 7.54073773,5.06586399 L4.53553391,2.06066017 L1.53033009,5.06586399 Z M7.54073773,10.0052038 C7.83363094,9.7123106 8.30850468,9.7123106 8.6013979,10.0052038 C8.89429112,10.298097 8.89429112,10.7729708 8.6013979,11.065864 L5.06586399,14.6013979 C4.77297077,14.8942911 4.29809704,14.8942911 4.00520382,14.6013979 L0.469669914,11.065864 C0.176776695,10.7729708 0.176776695,10.298097 0.469669914,10.0052038 C0.762563133,9.7123106 1.23743687,9.7123106 1.53033009,10.0052038 L4.53553391,13.0104076 L7.54073773,10.0052038 Z' id='arrows-light' fill='#{$plum}' fill-rule='nonzero'></path></g></svg>");
          }
        }
      }

      li {
        font-size: 17px;
        display: inline-block;
        margin-left: 15px;
        user-select: none;

        &:hover {
          ul li a {
            color: $plum;

            .color-dark & {
              color: $rose;
              @include mobile {
                color: $plum;
              }
            }

            .color-dark.header-out & {
              color: $plum;
            }
          }
        }

        &:first-child {
          margin-left: 0;
        }

        a {
          padding: 0 0 2px;
          position: relative;
          @include mobile {
            margin: 10px 0;
          }

          &.active {
            border-bottom: 2px solid $highlight;

            .color-dark & {
              border-bottom-color: $highlight;
            }

            .color-dark.header-out & {
              border-bottom-color: $highlight;
            }

            &:hover {
              color: $plum;

              .color-dark & {
                color: $rose;
                @include mobile {
                  color: $plum;
                }
              }

              .color-dark.header-out & {
                color: $plum;
              }
            }
          }
        }

        ul {
          position: absolute;
          visibility: hidden;
          padding-bottom: 10px;

          li {
            text-align: left;
            font-size: 16px;
            display: flex;
            float: left;
            margin: 0 10px;
            opacity: 0;
            transition: color 0.4s ease;

            &:hover {
              a {
                color: $plum-050;

                .color-dark & {
                  color: $rose-050;
                  @include mobile {
                    color: $plum-050;
                  }
                }

                .color-dark.header-out & {
                  color: $plum-050;
                }

                &.active {
                  color: $plum-090;

                  .color-dark & {
                    color: $rose-090;
                  }

                  .color-dark.header-out & {
                    color: $plum-090;
                  }
                }
              }
            }

            a {
              display: block;
              padding: 5px 0 0;

              @include mobile {
                padding: 0;
                margin: 5px 0 5px 10px;
              }

              &.active {
                border-bottom: 2px solid $highlight;

                .color-dark & {
                  border-bottom-color: $highlight;
                }

                .color-dark.header-out & {
                  border-bottom-color: $highlight;
                }
              }
            }
          }

          li:nth-child(1) {
            transform: translateY(5%);
            transition-delay: 0.25s;
          }

          li:nth-child(2) {
            transform: translateY(10%);
            transition-delay: 0.2s;
          }

          li:nth-child(3) {
            transform: translateY(15%);
            transition-delay: 0.15s;
          }

          li:nth-child(4) {
            transform: translateY(20%);
            transition-delay: 0.1s;
          }

          li:nth-child(5) {
            transform: translateY(25%);
            transition-delay: 0.05s;
          }

          li:nth-child(1),
          li:nth-child(2),
          li:nth-child(3),
          li:nth-child(4),
          li:nth-child(5) {
            @include mobile {
              transform: none;
              transition: none;
            }
          }
        }

        &:hover {
          ul {
            visibility: visible;
            pointer-events: all;

            li {
              opacity: 1;
              transform: translateY(0);
              transition: transform 0.4s ease, opacity 0.4s ease;
              @include mobile {
                transform: none;
                transition: none;
              }
            }

            li:nth-child(1) {
              transition-delay: 0.05s;
            }

            li:nth-child(2) {
              transition-delay: 0.1s;
            }

            li:nth-child(3) {
              transition-delay: 0.15s;
            }

            li:nth-child(4) {
              transition-delay: 0.2s;
            }

            li:nth-child(5) {
              transition-delay: 0.25s;
            }
          }
        }
      }
    }
  }
}
