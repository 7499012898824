form {
  margin: 3rem 0 4rem;
}

.hidden {
  display: none;
}

.group {
  position: relative;
  margin-bottom: 2.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.select {
  width: 50%;
  @include mobile {
    width: 100%;
  }
}

.checkbox {
  display: flex;

  input {
    width: 40px;
    margin: 2px 10px 2px 0;
    float: left;
    @include mobile {
      width: 80px;
    }
  }

  span {
    font-size: small;

    a {
      font-weight: 500;
      border-bottom: 1px solid $plum-050;
      transition: border-color 0.1s ease;

      &:hover {
        border-bottom: 1px solid $plum-075;
      }
    }
  }
}

input,
select,
textarea {
  font-size: $font-size-default;
  padding: 5px 5px 10px;
  display: block;
  width: 100%;
  border: none;
  color: $plum;
  background: $background-color;
  border-bottom: 2px solid $plum-015;
  line-height: 1.4;

  &::placeholder {
    color: rgba(0,0,0,0);
    transition: color 0.2s ease 0s;
  }
}

select {
  border-radius: 0;
  -webkit-appearance: none;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<svg width='9px' height='15px' viewBox='0 0 9 15' version='1.1' xmlns='http://www.w3.org/2000/svg'><g id='home' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><path d='M1.53033009,5.06586399 C1.23743687,5.35875721 0.762563133,5.35875721 0.469669914,5.06586399 C0.176776695,4.77297077 0.176776695,4.29809704 0.469669914,4.00520382 L4.00520382,0.469669914 C4.29809704,0.176776695 4.77297077,0.176776695 5.06586399,0.469669914 L8.6013979,4.00520382 C8.89429112,4.29809704 8.89429112,4.77297077 8.6013979,5.06586399 C8.30850468,5.35875721 7.83363094,5.35875721 7.54073773,5.06586399 L4.53553391,2.06066017 L1.53033009,5.06586399 Z M7.54073773,10.0052038 C7.83363094,9.7123106 8.30850468,9.7123106 8.6013979,10.0052038 C8.89429112,10.298097 8.89429112,10.7729708 8.6013979,11.065864 L5.06586399,14.6013979 C4.77297077,14.8942911 4.29809704,14.8942911 4.00520382,14.6013979 L0.469669914,11.065864 C0.176776695,10.7729708 0.176776695,10.298097 0.469669914,10.0052038 C0.762563133,9.7123106 1.23743687,9.7123106 1.53033009,10.0052038 L4.53553391,13.0104076 L7.54073773,10.0052038 Z' id='arrows-light' fill='#{$plum}' fill-rule='nonzero'></path></g></svg>");
}

input:focus,
select:focus,
textarea:focus {
  color: $plum;
  outline: none;
  border-bottom: 2px solid rgba(0,0,0,0);

  &::placeholder {
    color: $plum-025;
    transition: color 0.3s ease 0.1s;
  }
}

// label
label {
  color: $plum;
  font-size: 1rem;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 5px;
  transition: 0.2s ease all;
  cursor: text;
}

// active state
input:focus ~ label,
input:valid ~ label,
select:focus ~ label,
select:valid ~ label,
textarea:focus ~ label,
textarea:valid ~ label {
  top: -20px;
  font-size: $font-size-x-smaller;
  color: $plum-085;
}

// bottom bars
.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:after,
.bar:before {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: $plum;
  border: none;
  transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

// active state
input:focus ~ .bar:after,
input:focus ~ .bar:before,
select:focus ~ .bar:after,
select:focus ~ .bar:before,
textarea:focus ~ .bar:after,
textarea:focus ~ .bar:before {
  width: 50%;
}

// highlighter
.highlight {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

// active state
input:focus ~ .highlight,
select:focus ~ .highlight,
textarea:focus ~ .highlight {
  animation: inputHighlighter 0.3s ease;
}

button {
  @include btn-regular;
  background-color: rgba(0,0,0,0);
  transition-property: color background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  &:hover {
    color: $background-color;
    background-color: $plum;
  }
}

@keyframes inputHighlighter {
  from {
    background: $plum-050;
  }

  to {
    width: 0;
    background: transparent;
  }
}
