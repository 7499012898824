footer {
  position: relative;
  bottom: 0;
  margin-top: $spacing-top;

  .footer {
    padding: 15px 0 20px;
    border-top: 3px solid $plum;
    @include mobile {
      border-top: 2px solid $plum-025;
    }

    li {
      display: inline-block;
    }

    .footer_prime {
      font-size: $font-size-small;
      @include link-color;

      .footer_prime-nav {
        li {
          margin-right: 15px;
          @include mobile {
            margin-right: 20px;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        ul {
          @include mobile {
            display: flex;
            justify-content: center;
          }
        }

      }

      .footer_socials-nav {
        li {
          margin-left: 15px;
          @include mobile {
            margin-left: 20px;
          }

          &:first-child {
            margin-left: 0;
          }
        }

        ul {
          @include mobile {
            display: flex;
            justify-content: center;
          }
        }
        @include mobile {
          padding: 15px 0;
          margin: 15px 0;
          border-top: 2px solid $plum-050;
          border-bottom: 2px solid $plum-050;
          @include mobile {
            border-top: 2px solid $plum-025;
            border-bottom: 2px solid $plum-025;
          }
        }
      }
    }

    .footer_second {
      margin-top: 50px;
      font-size: $font-size-x-smaller;
      font-weight: 400;
      color: $plum-050;
      @include link-color-reverse;

      @include mobile {
        text-align: center;
        margin-top: 0;
        line-height: 2;
      }

      .footer_second-nav {
        @include mobile {
          margin: 15px 0 5px;
          text-align: center;
        }

        li {
          margin-left: 4px;

          &:after {
            content: '•';
            margin-left: 6px;
          }

          &:last-child:after {
            content: '';
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }

      .footer_second-copy {
        @include mobile {
          text-align: center;
          margin: 20px 0 10px;
        }
      }
    }
  }
}
