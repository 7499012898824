.treatments-home {
  margin-bottom: $spacing-bottom;
}

.treatments {
  padding-top: $spacing-top * 1.5;
  display: inline-block;
  width: 100%;

  @include mobile {
    margin-bottom: $spacing-bottom / 2;
  }

  h4 {
    margin: 0 0 0.67em;
  }

  .treatments-header {
    margin-bottom: $spacing-bottom * 1.5;

    @include mobile {
      margin: ($spacing-bottom / 2) 0 $spacing-bottom 0;
    }

    h1 {
      margin: 0;
    }
  }

  .treatments-back {
    display: inline-block;
    margin: 0.5rem;
    padding: 0.5rem;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .treatment {
    position: relative;
    height: 440px;
    margin: 0 0 20px;
    overflow: hidden;
    user-select: none;

    h3 {
      margin: 0;
    }

    .treatment-img {
      position: relative;
      transition: transform 12s ease-in;
      z-index: -1;
      height: 100%;
      background: black;

      img {
        opacity: 0.8;
        object-fit: cover;
        height: inherit;
      }
    }

    &:hover {
      .btn-regular {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      .treatment-img {
        transform: scale(1.1);
        transition: transform 6s ease-out;

        @include mobile {
          transform: none;
        }
      }
    }

    &.color-dark {
      color: $rose;

      .btn-regular {
        border: 1px solid $rose;

        @include mobile {
          color: $plum;
          background: $rose;
        }

        &:hover {
          color: $plum;
          background: $rose;
        }
      }
    }

    .treatment-headline {
      position: absolute;
      top: 25px;
      left: 30px;
      margin: 0;
      padding-right: $spacing * 2;
      z-index: 1;

      .h2,
      h2 {
        margin: 0 0 0.5rem;
      }

      h3 {
        max-width: 275px;
      }
    }

    .btn-regular {
      position: absolute;
      bottom: 30px;
      left: 30px;
      z-index: 1;

      @include btn-regular;

      margin-bottom: 0;
      opacity: 0;
      transform: translate3d(0, 5%, 0);
      transition: all 0.2s ease;

      &:hover {
        color: $rose;
        background: $plum;
      }

      @include mobile {
        color: $rose;
        background: $plum;
        opacity: 1;
        transform: none;
        transition: none;
      }
    }
  }
}
