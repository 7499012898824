.intro-teaser {
  margin-bottom: $spacing-bottom;

  @include mobile {
    margin-bottom: calc(#{$spacing-bottom} / 2);
  }
}

.contact-teaser {
  margin: $spacing-bottom 0;
  display: inline-block;
  width: 100%;

  @include mobile {
    margin: calc(#{$spacing-bottom} / 2) 0;
  }

  .h2 {
    margin: 0 0 2rem;
  }

  a.contact-teaser_phone:hover {
    color: $highlight;
  }

  i.fa-phone {
    font-size: 80%;
    margin-right: 1rem;
  }
}

.leistungen {
  .content-header {
    max-height: 720px;
  }
}

.blog-teaser {
  position: relative;
  overflow: hidden;
  color: $rose;
  margin: $spacing-top $spacing;

  .blog-teaser-txt {
    position: relative;
    z-index: 1;
    padding: ($spacing-bottom * 1.5) 0 ($spacing-bottom * 2) 0;

    .btn-regular {
      color: $plum;
      background: $rose;
      border: none;

      &:hover {
        color: $rose;
        background: $plum;
      }
    }

    .h2 {
      color: $white;

      &:hover {
        color: $rose;
      }
    }

    .h3 {
      color: $rose;

      &:hover {
        color: $rose;
      }
    }

    .blog-teaser_headline {
      margin-bottom: $spacing-bottom / 3;

      .h2 {
        color: $highlight;
      }
    }

    .blog-articles-latest {
      margin: ($spacing-bottom / 4) 0 ($spacing-bottom / 4) 0;
    }

    .blog-articles {
      // margin-bottom: $spacing-bottom / 2;

      a {
        &:hover {
          color: $highlight;
        }
      }

      article {
        padding: ($spacing-bottom / 2) 0;
        border-top: 1px solid $plum-010;

        h3 {
          hyphens: auto;
          margin-bottom: 5px;
        }

        p {
          margin: 0;
          font-size: 1rem;
          color: $rose-075;

          @include mobile {
            font-size: 1rem;
          }
        }

        time {
          color: $plum-050;
        }
      }

      article:first-child {
        padding-top: 0;
        border: none;
      }
    }
  }

  .blog-teaser_bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
