@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i');

@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 400;
  src: local('.SFNSText'), local('.BlinkMacSystemFont'), local('.HelveticaNeueDeskInterface'), local('Roboto');
}

$font-size-default: 18px;
$font-size-small: 18px;
$font-size-smaller: 16px;
$font-size-x-smaller: 13px;

// Default font stack
$font-stack-head: 'Roboto', Helvetica, Arial, sans-serif;
$font-stack-copy: 'Roboto', Helvetica, Arial, sans-serif;
$font-stack-default: Helvetica, Arial, sans-serif, 'Roboto';