@import './common/normalize';
@import './common/helper';
@import './common/mixins';
@import './common/fonts';
@import './common/fa';
@import './common/breakpoints';
@import './common/grid';
@import './common/colors';
@import './common/reset';

@import './components/main';

@import './components/header';
@import './components/content';
@import './components/home';
@import './components/treatment';
@import './components/footer';
@import './components/form';
@import './components/blog';

$ka-cookie-bg: #222 !default;
$ka-cookie-color: #fff !default;
$ka-cookie-link-color: #cccccc !default;
$ka-cookie-link-color-hover: #cccccc !default;
$ka-cookie-btn-bg: #ffffff !default;
$ka-cookie-btn-color: #222 !default;
$ka-cookie-position: bottom-right !default;
@import "../../public/site/plugins/cookie/cookie.scss";