@mixin aspect-ratio( $width, $height ) {
    position: relative;
    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: $height / $width * 100%;
    }
}

@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin link-color {
  a,
  a:link {
    color: $plum;
    transition: color 0.15s ease;
    &:hover {
      color: $plum-050;
    }
  }
}

@mixin link-color-reverse {
  a,
  a:link {
    color: $plum-050;
    transition: color 0.15s ease;
    &:hover {
      color: $plum;
    }
  }
}

@mixin btn-regular {
  padding: 0 15px;
  margin: 0.5rem 0;
  height: 44px;
  line-height: 42px;
  font-size: 16px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  border: 1px solid $plum;
  border-radius: 6px;
}

// Vendor prefixes
$sass-burger-add-vendor-prefixes: true !default;

// Burger
@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
    $burger-height: $height !global;
    $burger-gutter: $gutter !global;

    position: relative;
    margin-top: $height + $gutter;
    margin-bottom: $height + $gutter;

    @if $sass-burger-add-vendor-prefixes {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
    user-select: none;

    // 1. Fixes jagged edges in Firefox, see issue #10.
    &, &::before, &::after {
        display: block;
        width: $width;
        height: $height;
        background-color: $color;
        outline: 1px solid transparent; // 1
        @if $border-radius != 0 {
            border-radius: $border-radius;
        }

        @if $sass-burger-add-vendor-prefixes {
            -webkit-transition-property: background-color, -webkit-transform;
            -moz-transition-property: background-color, -moz-transform;
            -o-transition-property: background-color, -o-transform;
        }
        transition-property: background-color, transform;

        @if $sass-burger-add-vendor-prefixes {
            -webkit-transition-duration: $transition-duration;
            -moz-transition-duration: $transition-duration;
            -o-transition-duration: $transition-duration;
        }
        transition-duration: $transition-duration;
    }

    &::before, &::after {
        position: absolute;
        content: "";
    }

    &::before {
        top: -($height + $gutter);
    }

    &::after {
        top: $height + $gutter;
    }
}


// Select parts of the burger
@mixin burger-parts {
    &, &::before, &::after {
        @content;
    }
}

@mixin burger-top {
    &::before {
        @content;
    }
}

@mixin burger-middle {
    & {
        @content;
    }
}

@mixin burger-bottom {
    &::after {
        @content;
    }
}


// Burger animations
@mixin burger-to-cross($color: auto) {
  & {
    background-color: transparent;
  }
  @if ($color != auto) {
    &::before, &::after {
      background-color: $color;
    }
  }
  &::before {
    transform: translateY($burger-gutter + $burger-height) rotate(45deg);
  }
  &::after {
    transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
  }
}
