.blog {
  .blog-intro,
  .blogarticle-intro {
    .h1 {
      margin: 0;
    }

    a {
      &:hover {
        color: currentColor;

        h2,
        h3 {
          color: currentColor;
        }
      }
    }

    .h2,
    .h3 {
      display: inline;
      color: currentColor;
    }
  }

  .blog-intro {
    padding: ($spacing-top * 2.5) 0 $spacing-top 0;
  }

  .blogarticle-intro {
    padding: ($spacing-top * 2.5) 0 ($spacing-top * 0.25) 0;

    time {
      font-size: 0.95rem;
      display: inline-block;
      color: $plum-075;
      margin: 0.5rem 0;
    }
  }

  .blogarticle-footer {
    .breadcrumb {
      padding-top: 1rem;

      @include mobile {
        margin: 0 0 $spacing-top;
      }
    }
  }

  .blogarticle-articles {
    margin-top: $spacing-top;
  }

  img {
    vertical-align: bottom;
  }

  article {
    padding: $spacing-top 0;
    border-bottom: 1px solid $plum-010;

    @include mobile {
      padding: ($spacing-top / 2) 0 ($spacing-top / 2) 0;
    }

    .blog-articles-row {
      @include mobile {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .h2,
    .h3 {
      display: inline;
    }

    a.btn-regular {
      opacity: 0.25;

      @include mobile {
        opacity: 1;
      }
    }

    p {
      color: $plum-075;
      font-size: 1rem;
      line-height: 1.4;

      // font-weight: 200;
      margin: 0.75rem 3rem 1rem 0;

      @include mobile {
        font-size: 1rem;
        margin: 0.75rem 0 1rem;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    time {
      margin: 0 0 2.5rem;
      display: block;
      font-size: 0.9rem;
      color: $plum-050;
      transition: color 0.2s ease;

      @include mobile {
        margin: 0 0 1.5rem;
      }
    }

    .blogarticle-img {
      margin: 0.5rem 0;

      @include mobile {
        margin-bottom: 1rem;
      }
    }

    &:hover {
      a.btn-regular {
        opacity: 1;
      }

      a.blogarticle-read {
        color: $plum;
      }
    }
  }

  nav {
    @include mobile {
      margin: 0 auto;
    }

    &.nav-blog {
      margin-top: $spacing-top;
    }

    &.nav-blog,
    &.nav-blogarticle {
      display: inline-block;
      text-align: center;
      font-weight: 500;

      ul {
        margin: 0 auto;

        li {
          float: left;
          list-style: none;
          border: 1px solid $plum-010;
          border-right-width: 0;

          &:first-child,
          &:last-child {
            a,
            span {
              padding: 10px 22px;
            }
          }

          &:first-child {}

          &:last-child {
            border-right-width: 1px;
          }

          a,
          span {
            padding: 10px 20px;
            display: inline-block;
            color: $plum-075;

            &.active {
              color: $plum;
              box-shadow: inset 0 -2px 0 0 $highlight;
            }
          }

          span {
            color: $plum-010;
          }

          a {
            &:hover {
              color: $plum;
            }
          }
        }
      }
    }
  }
}
