// Import gridle:
@import "./../vendor/gridle/gridle";

// setup the grid (required):
$columns: 12;
$spacing: 20px;

@include gridle_setup((
  context: $columns,
  gutter-width: $spacing,
  debug: true,
  debug-show-class-names: false
));

// register states
// @include gridle_register_state( default, ( min-width: $bp-default ) );
@include gridle_register_state( tablet, ( min-width: $bp-mobile, max-width: $bp-tablet ) );
@include gridle_register_state( mobile, ( max-width: $bp-mobile ) );

// generate classes:
@include gridle_generate_classes;

// set up gird container:
.grid-wrapper {
  position: relative;
  width: 100%;
  max-width: $bp-13inch;
  margin: 0 auto;
  padding: 0 $spacing;

  @include clearfix;

}

// Grid Overlay — remove for production (KeyCode: G)
#gridoverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: $zIndex-10;
  display: none;

  &.show {
    display: block;
  }

  .grid-wrapper, .row {
    position: relative;
    height: 100%;
  }

  .grid-wrapper {
    border-left: 1px solid cyan;
    border-right: 1px solid cyan;
    box-sizing: border-box;
  }

  .grid-1 {
    height: 100%;
    position: relative;
    &:after {
      content: ' ';
      display: block;
      width: 100%;
      height: 100%;
      background-color: cyan;
      opacity: 0.5;
    }
  }
}
