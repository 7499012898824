.content-header {
  position: relative;
  top: 0;
  width: 100%;
  height: 45vw;
  min-height: calc((#{$bp-tablet}/2));
  max-height: calc((#{$bp-tablet}/3*2));
  overflow: hidden;
  @include mobile {
    height: 90vh;
  }

  .content-header_txt {
    position: absolute;
    width: 100%;
    bottom: 2rem;
    z-index: 3;

    h1 {
      margin-bottom: 1rem;
    }

    h2,
    h3,
    h4 {
      margin: 0.25rem 0.2rem;

      &.claim {
        margin-bottom: 2rem;
        max-width: 350px;
      }
    }
  }

  .content-header_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;

    &.cover {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.25);
      }

      img {

        object-fit: cover;
        height: inherit;
      }
    }

    &.contain {
      // img {
      //   object-fit: contain;
      //   height: inherit;
      // }
      img {
        max-width: 1440px;
      }
    }
    @include tablet {
      &.contain,
      &.cover {
        img {
          object-fit: cover;
          height: inherit;
        }
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: $plum-010;
  }
}

.content-body {
  position: relative;
  min-height: 20vh;
  margin: 1rem 0 0;

  a {
    &:hover {
      // color: $plum-075;
      cursor: pointer;
    }
  }

  .h4,
  h4 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
  }

  h2,
  h3 {
    margin: 2.5rem 0 0;
  }

  h2 + p,
  h3 + p {
    margin-top: 0.75rem;
  }

  h4 + p {
    margin-top: 0;
  }

  .hangline {
    margin: 1.9rem 0;
    border: 0;
    border-bottom: 2px solid $plum;
  }

  ul {
    margin: 1rem 0 1rem 1.25rem;
  }

  table {
    figure {
      margin: 0;

      img {
        display: block;
        mix-blend-mode: multiply;
      }
    }
  }

  .fluid-width-video-wrapper,
  figure {
    margin: 2.5rem 0 2rem;
  }

  figure {
    &.partner-logo {
      display: flex;
      justify-content: space-between;
      margin: 0 0 1.5rem;

      img {
        max-width: 200px;
        height: auto;
      }
    }
  }

  blockquote {
    position: inherit;
    line-height: 1.4;
    font-size: 1.45rem;
    font-weight: 300;
    padding: 0.25rem 2.5rem;
    margin: 2rem 0;
    display: inline-block;

    p {
      margin: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 6%;
      height: 88%;
      border-left: 2px solid $plum-015;
    }

    cite {
      font-size: $font-size-smaller;
      font-weight: 300;
      font-style: normal;

      &:before {
        display: block;
        margin-top: 1rem;
        font-size: 1rem;
        position: relative;
        bottom: -0.75rem;
        margin-top: -0.5rem;
        content: '—';
      }
    }
    // p {
    //   margin-bottom: 0;
    // }
  }
}

.gallery {
  font-size: 0;
  flex-flow: row wrap;
  display: flex;
  margin: 3rem -10px;
  @include mobile {
    padding: 0;
  }

  figure {
    flex: auto;
    width: 250px;
    margin: 10px;

    img {
      width: 100%;
      height: auto;
    }
  }
}
