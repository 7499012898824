* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  width: 100vw;
  font-size: $font-size-default;
}

body {
  font-family: $font-stack-copy;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: $plum;
  background-color: $background-color;

  &.overflow {
    overflow: hidden;
  }
}

a {
  hyphens: auto;
  color: $plum;

  &:link {
    color: $plum;
  }

  &.link {
    border-bottom: 2px solid $plum-025;
    padding-bottom: 1px;
    transition: all 0.1s ease;

    &:hover {
      padding-bottom: 3px;
      border-color: $plum;
    }
  }

  &.btn-regular {
    @include btn-regular;
    transition-property: color background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease;

    &:hover {
      color: $rose;
      background-color: $plum;
    }

    i.fa {
      margin-right: 0.2rem;
    }
  }
}

main {
  overflow: hidden;

  .color-dark & {
    .content-header_txt {
      color: $rose;

      a {
        color: $rose;

        &:link {
          color: $rose;
        }

        &.link {
          border-color: $rose-025;

          &:hover {
            border-color: $rose;
          }
        }

        &.btn-regular {
          &:hover {
            color: $rose;
            background-color: $rose;
          }
        }
      }
    }
  }
}

.h1,
h1 {
  font-size: 4rem;
  font-weight: 500;
  margin: 0 0 1.5rem;
  line-height: 1.05;
  letter-spacing: -0.05rem;
  @include mobile {
    font-size: 2rem;
    line-height: 1.1;
    margin-top: 0;
    hyphens: auto;
  }
}

.h2,
h2 {
  font-size: 1.85rem;
  font-weight: 500;
  line-height: 1.3;
  margin: 0 0 0.5rem;
  @include mobile {
    font-size: 1.45rem;
  }
}

.h3,
h3 {
  font-size: 1.45rem;
  font-weight: 500;
  line-height: 1.3;
}

.h4,
h4 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}

table {
  width: 100%;
  border-width: 1px 0 0 1px;
  border-color: $plum-010;
  border-style: solid;
  border-spacing: 0;
  border-collapse: separate;
  font-size: $font-size-smaller;
  line-height: 1.4;
  margin: 3rem 0;

  thead {
    // display: none;
    vertical-align: middle;
    border-color: inherit;
    font-weight: 500;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  tr {
    display: table-row;
    vertical-align: top;
    border-color: inherit;

    td,
    th {
      border-width: 0 1px 1px 0;
      border-color: $plum-010;
      border-style: solid;
      padding: 10px;
      display: table-cell;
      vertical-align: inherit;

      &:first-child {
        width: 50%;
        text-align: left;
      }

      &:nth-child(2) {
        width: 22%;
        text-align: left;
      }
    }
  }
}

nav {
  &.breadcrumb {
    margin: 0.6rem 0 0 0.1rem;
    font-size: 1rem;

    ul {
      margin: 0;

      li {
        float: left;
        margin: 0;
        display: inline-block;
        list-style: none;

        a {
          padding: 0.25rem 0.35rem 0.25rem 0.2rem;
          opacity: 0.75;

          &:hover {
            opacity: 1;
          }
        }

        &:before {
          opacity: 0.75;
          content: '/ ';
        }

        &:first-child {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

hr {
  border: none;
  margin: 3rem 0;
  border-top: 1px solid $plum-010;

  &.hr1 {
    border-top: 1px solid $plum-010;
  }

  &.hr2 {
    border-top: 2px solid $plum;
  }

  &.hr3 {
    border-top: 1px solid $plum;
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
  @include mobile {
    text-align: left;
  }
}

.align-center {
  text-align: center;
}
