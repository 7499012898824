html {
  width: 100vw;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
}

// Reset margins and use border-box
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  background: $black;
  color: $white;
}

img {
  width: 100%;
}

p  {
  margin: 1.5rem 0;
}

a, a:link {
  text-decoration: none;
}
