// black shades
$black-off: rgba(20,20,20,1);
$black-init: #000;
$black-100: rgba($black-init,1);
$black-090: rgba($black-init,.9);
$black-085: rgba($black-init,.85);
$black-075: rgba($black-init,.75);
$black-050: rgba($black-init,.5);
$black-025: rgba($black-init,.25);
$black-015: rgba($black-init,.15);
$black-010: rgba($black-init,.1);
$black: $black-init;

// white shades
$white-off: rgba(235,235,235,1);
$white-init: #fff;
$white-100: rgba($white-init,1);
$white-090: rgba($white-init,.9);
$white-085: rgba($white-init,.85);
$white-075: rgba($white-init,.75);
$white-050: rgba($white-init,.5);
$white-025: rgba($white-init,.25);
$white-015: rgba($white-init,.15);
$white-010: rgba($white-init,.1);
$white: $white-100;

// color pallete
// primury color #dbcdc3 #f7ece8 #f3ebe8

$rose-init: #ffffff;
$rose:      rgba($rose-init,1);
$rose-090:  rgba($rose-init,.9);
$rose-085:  rgba($rose-init,.85);
$rose-075:  rgba($rose-init,.75);
$rose-060:  rgba($rose-init,.60);
$rose-050:  rgba($rose-init,.5);
$rose-025:  rgba($rose-init,.25);
$rose-015:  rgba($rose-init,.15);
$rose-010:  rgba($rose-init,.10);
$rose-000:  rgba($rose-init,0);

// secondary color #372e37
$plum-init: #231f27;
$plum:      rgba($plum-init,1);
$plum-090:  rgba($plum-init,.9);
$plum-085:  rgba($plum-init,.85);
$plum-075:  rgba($plum-init,.75);
$plum-060:  rgba($plum-init,.60);
$plum-050:  rgba($plum-init,.5);
$plum-025:  rgba($plum-init,.25);
$plum-015:  rgba($plum-init,.15);
$plum-010:  rgba($plum-init,.10);


$background-color: #eae9e6;
$highlight-init: #137354;
$highlight:      rgba($highlight-init,1);
